*
{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body
{
  font-size: 16px;
  height: 100vh;
  max-width: 100vw;
  overflow: hidden;
  overflow: hidden;
}

body {
  background-color: #000;
  // font-family: 'Bebas Neue', cursive;
  font-family: 'Playfair Display', serif;
}

.webgl
{
  outline: none;
  position: fixed;
  right: 0;
  top: 0;
}

$bar-color: #fff;
$ball-color: #fff;
$bg-color: #EA4961;

.loading {
  height: 100px;
  left: 50%;
  opacity: 1;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 1s;
  width: 75px;
  z-index: 4;

  body.complete & {
    opacity: 0
  }
  
  &__bar {
    background: $bar-color;
    bottom: 0;
    box-shadow: 1px 1px 0 rgba(0,0,0,.2);
    height: 50%;
    position: absolute;
    transform-origin: center bottom;
    width: 10px;
    
    @for $i from 1 through 5 { 
       &:nth-child(#{$i}) {
         animation: barUp#{$i} 4s infinite;
         left: ($i - 1) * 15px;
         transform: scale(1,$i*.2);
        }
    }
    
  }
  
  &__ball {
    animation: ball 4s infinite;
    background: $ball-color;
    border-radius: 50%;
    bottom: 10px;
    height: 10px;
    left: 0;
    position: absolute;
    width: 10px;
  }
}

@keyframes ball {
  0% {
    transform: translate(0, 0);
  }
  5% {
    transform: translate(8px, -14px);
  }
  10% {
    transform: translate(15px, -10px)
  }
  17% {
    transform: translate(23px, -24px)
  }
  20% {
    transform: translate(30px, -20px)
  }
  27% {
    transform: translate(38px, -34px)
  }
  30% {
    transform: translate(45px, -30px)
  }
  37% {
    transform: translate(53px, -44px)
  }
  40% {
    transform: translate(60px, -40px)
  }
  50% {
    transform: translate(60px, 0)
  }
  57% {
    transform: translate(53px, -14px)
  }
  60% {
    transform: translate(45px, -10px)
  }
  67% {
    transform: translate(37px, -24px)
  }
  70% {
    transform: translate(30px, -20px)
  }
  77% {
    transform: translate(22px, -34px)
  }
  80% {
    transform: translate(15px, -30px)
  }
  87% {
    transform: translate(7px, -44px)
  }
  90% {
    transform: translate(0, -40px)
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes barUp1 { 
  0% {
    transform: scale(1, .2);
  }
  40%{
    transform: scale(1, .2);
  }
  50% {
    transform: scale(1, 1);
  }
  90% {
    transform: scale(1,1);
  }
  100% {
    transform: scale(1,.2);
  }
}
@keyframes barUp2 { 
  0% {
    transform: scale(1, .4);
  }
  40% {
    transform: scale(1, .4);
  }
  50% {
    transform: scale(1, .8);
  }
  90% {
    transform: scale(1, .8);
  }
  100% {
    transform: scale(1, .4);
  }
}
@keyframes barUp3 { 
  0% {
    transform: scale(1, .6);
  }
  100% {
    transform: scale(1, .6);
  }
}
@keyframes barUp4 { 
  0% {
    transform: scale(1, .8);
  }
  40% {
    transform: scale(1, .8);
  }
  50% {
    transform: scale(1, .4);
  }
  90% {
    transform: scale(1, .4);
  }
  100% {
    transform: scale(1, .8);
  }
}
@keyframes barUp5 { 
  0% {
    transform: scale(1, 1);
  }
  40% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, .2);
  }
  90% {
    transform: scale(1, .2);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes panelSlideLeft {
  0% { left: 0 }
  100% { left: -60vw }
}
@keyframes panelSlideRight {
  0% { left: 50% }
  100% { left: 110vw }
}

.slide-panel {
  background: #000;
  height: 100vh;
  outline: none;
  position: absolute;
  top:0;
  width: 50vw;
  z-index: 3;

  &--left {
    left:0;

    &:after {
      border-color: transparent transparent transparent #000000;
      border-style: solid;
      border-width: 100vh 0 0 10vw;
      content: "";
      display: inline-block;
      height: 0;
      position: absolute;
      right: -9.5vw;
      top: 0;
      width: 0;
    }

    .complete & {
      animation: panelSlideLeft 1.5s;
      animation-delay: 1.5s;
      animation-fill-mode: forwards;
    }
  }

  &--right {
    left: 50%;

    &:before {
      border-color: transparent #000000 transparent transparent;
      border-style: solid;
      border-width: 0 10vw 100vh 0;
      content: "";
      display: inline-block;
      height: 0;
      left: -9.5vw;
      position: absolute;
      top: 0;
      width: 0;
    }

    .complete & {
      animation: panelSlideRight 1.5s;
      animation-delay: 1.5s;
      animation-fill-mode: forwards;
    }
  }
}

@keyframes showTopText {
  0% { transform: translate3d(0, 100%, 0); }
  100% { transform: translate3d(0, 0, 0); }
}
@keyframes showBottomText {
  0% { transform: translate3d(0, -100%, 0); }
  100% { transform: translate3d(0, 0, 0); }
}

.headings {
  bottom: 15vmin;
  color: #FFF;
  height: 23vh;
  left: 50%;
  opacity: 1;
  overflow: hidden;
  position: absolute;
  transform: translateX(-50%);
  transition: opacity 0.3s;
  width: 80vmin;
  z-index: 2;

  @media only screen and (min-width: 769px) {
    left: calc(50% + 30px);
  }

  .expanded & {
    opacity: 0
  }

  &__top, &__bottom {
    height: 50%;
    overflow: hidden;
    position: absolute;
    text-align: center;
    width: 100%;
  }

  &__top {
    border-bottom: solid #fff;
    top: 0;
    transition: width 0.3s 2s;
    width: 0;

    .complete & {
      width: 100%;
    }
  }

  &__bottom {
    bottom: 0;
  }

  h1, h2 {
    position: absolute;
    width: 100%;
  }

  h1 {
    font-size: 10vmin;
    line-height: 1.4;

    .complete & {
      animation: showTopText 0.5s;
      animation-delay: 2.5s;
      animation-fill-mode: forwards;
      bottom: 0;
      transform: translate(0, 100%);
    }
  }

  h2 {
    font-size: 5vmin;

    .complete & {
      animation: showBottomText 0.5s;
      animation-delay: 3s;
      animation-fill-mode: forwards;
      top: 0;
      transform: translate(0, -100%);
    }
  }
}

.menu {
  height: 100%;
  position: fixed;
  background-color: #000000;
  width: 100vw;
  transition: 1000ms all cubic-bezier(0.19, 1, 0.22, 1);
  transform: translateY(100%);
  bottom: 60px;
  z-index: 3;

  @media only screen and (min-width: 769px) {
    bottom: auto;
    height: 100%;
    position: fixed;
    width: 35vw;
    transform: translateX(-100%);
    left: 60px;
  }

  .expanded & {
    bottom: 0;
    transform: translateY(0%);

    @media only screen and (min-width: 769px) {
      transform: translateX(0%);
      left: 0px;
    }
  }

  &__inner {
    color: #fff;
    height: 100%;
    padding: 60px;
    position: relative;
    width: 100%;
    opacity: 0;
    transition: opacity 1s;
    display: flex;
    flex-direction: column;

    p {
      font-size: 5vw;
      left: 0;
      text-align: center;
      width: 100%;
      font-family: 'Roboto', sans-serif;

      @media only screen and (min-width: 769px) {
        font-size: 1.6vw;
      }
    }

    h3 {
      font-size: 4vmin;
      margin-top: 5vmin;
      text-align: center;
      width: 100%;
      font-family: 'Roboto', sans-serif;
    }

    a {
      color: #fff;
      text-decoration: none;

      &:hover, &:focus {
        text-decoration: underline;
      }
    }

    .expanded & {
      opacity: 1;
    }
  }

  &__arrow {
    border: solid white;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 10px;
    position: absolute;
    right: 50%;
    top: 20px;
    transform: rotate(-135deg) translateX(-50%);

    @media only screen and (min-width: 769px) {
      right: 20px;
      top: 50%;
      transform: rotate(-45deg) translateY(-50%);
    }

    .expanded.touch & {
      display: none;
    }

    .touch & {
      z-index: 2;
    }
  }

  &__close {
    display: none;
    position: absolute;
    right: 50px;
    top: 20px;
    z-index: 2;

    &:before, &:after {
      position: absolute;
      left: 15px;
      content: ' ';
      height: 33px;
      width: 2px;
      background-color: #FFF;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }

    .expanded.touch & {
      display: inline-block;
    }
  }

}

#blob {
  height: 100%;
  opacity: 0;
  position: absolute;
  right: 60px;
  top: 0;
  transform: translateX(100%);
  z-index: -1;

  .nav-ready & {
    opacity: 1;
  }

  .touch & {
    display: none;
  }
}

#blob-path {
  fill:  #000000;
  height: 100%;
}


/* Wrap the SVG in a div */
.cog-wheels {
  height: 300px;
  margin: 2% auto;
  width: 300px;
}

/*Rotate cogs*/

svg .cog-blue {
  animation-delay: 0.8s;
  animation: spin 10s linear infinite;
  transform-origin: 306.785px 190.5px;
}

svg .cog-red {
  animation-delay: 0.8s;
  animation: spin-reverse 10s linear infinite;
  transform-origin: 207.352px 357.835px;
}

svg .cog-yellow {
  animation-delay: 0.8s;
  animation: spin 10s linear infinite;
  transform-origin: 393.785px 409.5px;
}

@keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin-reverse {
  100% {
    -moz-transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
